<template>
  <span style="display: none;"></span>
</template>

<script>
export default {
  name: 'SoundPlayer',

  data() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    let context = new AudioContext();

    // Polyfill for old callback-based syntax used in Safari.
    if (context.decodeAudioData.length !== 1) {
      const originalDecodeAudioData = context.decodeAudioData.bind(context);
      context.decodeAudioData = buffer =>
        new Promise((resolve, reject) =>
          originalDecodeAudioData(buffer, resolve, reject)
        );
    }

    window.fetch(this.src)
      .then(response => response.arrayBuffer())
      .then(buffer => context.decodeAudioData(buffer))
      .then((sample) => {
        this.sample = sample;
      });

    return {
      context: context,
      sample: null,
    }
  },

  methods: {
    play(rate = 1.0) {
      if (!this.$session._sounds) return;
      if (this.sample === null) return;
      if (!this.context) return;

      const source = this.context.createBufferSource();
      source.buffer = this.sample;
      source.playbackRate.value = rate;

      let gainNode = this.context.createGain(); // Create a gainNode reference.
      gainNode.connect(this.context.destination); // Add context to gainNode
      gainNode.gain.value = this.volume;

      source.connect(gainNode);
      source.start(0);
    },

    interact() {
      if (this.context !== undefined && this.context !== null && this.context.state === 'suspended' && this.context.resume) {
        this.context.resume();
      }
    },
  },

  props: ['src', 'volume'],
}
</script>
